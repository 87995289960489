
















import Vue from "vue";
import ItemSelector from "../Common/ItemSelector.vue";
import MessageCardCard from '../MessageCard/MessageCardCard.vue';
import { Item, SelectedMessageCard } from "@/components/CartCommon/cart";
import MessageCardIcon from "../Common/Icons/MessageCardIcon.vue";


export default Vue.extend({
  props: {
    itemIndex: Number as () => number,
  },
  components: {
    ItemSelector,
    MessageCardCard,
    MessageCardIcon,
  },
  data() {
    return {
      isModalOpen: false,
    };
  },
  computed: {
    item(): Item | null {
      return this.$store.state.items[this.itemIndex];
    },
    selectedMessageCard(): SelectedMessageCard | null {
      return this.item!!.selectedOptions.messageCard;
    },
    messageCards(): Array<SelectedMessageCard> | null {
      return this.$store.state.commonMasterData.messageCards;
    },
    hasMultipleCards(): boolean {
      return this.messageCards!!.length >= 3;
    },
  },
  methods: {
    currentMessageCard(messageCardId: number) {
      return this.selectedMessageCard!!.id == messageCardId;
    },
  },
});
