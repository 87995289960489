
























import Vue from "vue";
import { SelectedWrapping } from "@/components/CartCommon/cart";

export default Vue.extend({
  props: {
    wrappings: {
      type: Array as () => SowExp.Wrapping[],
    },
    initialWrapping: {
      type: Object as () => SelectedWrapping,
    },
  },
  data() {
    return {
      selectedWrappingId: this.initialWrapping.id,
    };
  },
  computed: {
    selectedWrapping(): SowExp.Wrapping {
      return this.wrappings.find(w => w.id == this.selectedWrappingId)!!;
    },
    wrappingsInStock(): SowExp.Wrapping[] {
      return this.wrappings.filter(wrapping => wrapping.backordered === false);
    },
    sortedWrappings(): SowExp.Wrapping[] {
      return this.wrappingsInStock.concat().sort((a, b) => {
        return a.name < b.name ? 1 : a.name > b.name ? -1 : 0;
      });
    },
  },
  watch: {
    selectedWrapping() {
      const selectedWrapping: SelectedWrapping = this.wrappings.find(w => w.id == this.selectedWrappingId)!!;
      this.$emit("wrappingChanged", selectedWrapping);
    },
  },
  methods: {
    onImageClicked() {
      // todo: 拡大画像を表示
      console.log("image clicked");
    },
  },
});
