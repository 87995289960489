
















import Vue from "vue";
import ItemSelector from "../Common/ItemSelector.vue";
import GiftBagCard from "./GiftBagCard.vue";
import { Item, SelectedGiftBag } from "@/components/CartCommon/cart";
import GifBagIcon from "../Common/Icons/GiftBagIcon.vue";

export default Vue.extend({
  props: {
    itemIndex: Number as () => number,
  },
  components: {
    ItemSelector,
    GiftBagCard,
    GifBagIcon,
  },
  computed: {
    item(): Item | null {
      return this.$store.state.items[this.itemIndex];
    },
    selectedGiftBag(): SelectedGiftBag | null {
      return this.item!!.selectedOptions.giftBag;
    },
    giftBags(): Array<SelectedGiftBag> | null {
      return this.$store.state.commonMasterData.giftBags;
    },
    hasMultipleCards(): boolean {
      return this.giftBags!!.length >= 3;
    },
  },
  methods: {
    currentGiftBag(giftBagId: number): boolean {
      return this.selectedGiftBag?.id == giftBagId;
    },
  },
});
