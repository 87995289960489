












































































































































import Vue from "vue";
import axios from "axios";
import BreadCrumb from "@/components/Reserve2019/BreadCrumb.vue";
import { IInquiry } from "@/components/Reserve2019/interfaces";
import { api } from "@/api/apiclient";
import { VueReCaptcha } from 'vue-recaptcha-v3'

const node = document.getElementById("reserve2019")!;
const googleRecaptchaSiteKey = node.dataset.googleRecaptchaSiteKey;

Vue.use(VueReCaptcha, {
  siteKey: googleRecaptchaSiteKey,
  loaderOptions: {
    autoHideBadge: false
  }
})

const INQUIRY_CATEGORIES_PATH = "/reserve/faq_categories.json";
const INQUIRIES_PATH = "/reserve/inquiries.json";
const FETCH_CUSTOMER_PATH = "/reserve/fetch_customer";

export default Vue.extend({
  mixins: [],
  data() {
    return {
      errors: [],
      subjects: {
        "予約手配に関するメールが届かない": ["手配状況をお調べします。お名前、チケット番号、メールアドレスをご記入の上、お問い合わせください。手配状況は予約サイトのマイページからもご確認いただけます。", ""],
        "天候不良で体験中止になったお客様": ["予約番号（CT-）もしくはチケット番号をお知らせください。",
                                         "○予約番号もしくはチケット番号\n\n○お問い合わせの詳細"],
        "ログインできない": ["ご登録状況をお調べします。お名前、チケット番号、メールアドレスをご記入の上、お問い合わせください。", ""],
        "メールアドレス変更": ["現在のメールアドレスと新たに設定するメールアドレスをお知らせください。登録情報との一致を確認できましたら弊社で変更します。",
                             "○現在のメールアドレス\n\n○新たに設定するメールアドレス\n\n○お問い合わせの詳細"],
        "退会希望": ["ご登録のお名前とメールアドレスをご記入の上、お問い合わせください。",""],
        "チケットの期限について": ["体験期限の延長はできません。チケットの期限が不明な場合はチケット番号をご記入のうえ、お問い合わせください。",""],
        "その他のお問い合わせ": ["その他のご質問やご相談については、できる限り詳細をご記入のうえ、お問い合わせください。",""]
      },
      emailNotMatched: false,
      customer: null as ICustomer | null,
      isConfirm: false,
      title: "マイページ",
      invalid: false,
      faqCategories: [],
      historyPagePath: "/reserve/inquiry",
      breadcrumbs: [{ title: "お問い合わせ", link: null }],
      customerFetched: false,
      firstName: "",
      lastName: "",
      emailConfirmation: "",
      finished: false,
      isPosting: false,
      subjectNotice: "",
      agreed: false,
      placeholder: "",
      inquiry: {
        subject: "",
        body: "",
        name: "",
        email: "",
        ticket_number: ""
      } as IInquiry
    };
  },
  components: {
    BreadCrumb,
  },
  created() {
    this.getFaqCategories();
  },
  mounted() {
    this.fetchCustomer();
  },
  watch: {
    'inquiry.subject': function (newInquiry, oldInquiry) {
      this.subjectNotice = this.subjects[this.inquiry.subject][0];
      this.placeholder = this.subjects[this.inquiry.subject][1];
    },
    '$store.getters.ticket': {
      handler(newTicket, oldTicket) {
        this.inquiry.ticket_number = newTicket?.number || "";
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    confirm(): void {

      if (this.inquiry.email != this.emailConfirmation) {
        this.emailNotMatched = true;
      }
      else {
        this.emailNotMatched = false;
        this.inquiry.name = `${this.lastName}${this.firstName}`;
      }

      if (this.isValidInquiry() && this.agreed) {
        this.invalid = false;
        this.isConfirm = true;
      }
      else {
        this.invalid = true;
      }
    },
    async postInquiry() {
      this.isPosting = true;


      await this.$recaptchaLoaded();
      const token = await this.$recaptcha('inquiry');

      const params = {
        inquiry: {
          subject: this.inquiry.subject,
          body: this.inquiry.body,
          name: this.inquiry.name,
          email: this.inquiry.email,
          ticket_number: this.inquiry.ticket_number
        },
        google_recaptcha_token: token
      }

      let res = await api.post(INQUIRIES_PATH, params);
      this.isPosting = false;
      if(res.status == 201) {
        this.finished = true;
      }
      else {
        this.isConfirm = false;
        this.errors = res.data;
      }
    },
    isValidInquiry() {
      return this.inquiry.subject.length > 0 &&
             this.inquiry.body.length > 0 &&
             this.firstName.length > 0 &&
             this.lastName.length > 0 &&
             this.inquiry.email.length > 0 &&
             this.emailNotMatched === false
    },
    back(): void {
      this.isConfirm = false;
    },
    async getFaqCategories() {
      const ret = await axios.get(INQUIRY_CATEGORIES_PATH);
      this.faqCategories = ret.data;
    },
    async fetchCustomer() {
      try {
        const ret = await axios.get(FETCH_CUSTOMER_PATH);
        const c = ret.data;
        
        this.customer = {
          last_name: c.last_name,
          first_name: c.first_name,
          email: c.email,
          user_attributes: {
            id: c.user_id,
            email: c.email,
            login: c.email,
            password: null,
            password_confirmation: null
          }
        };
      } catch (e) {
        alert("エラーがおきました");
        console.error(e);
      }

      this.inquiry.email = this.customer?.email || "";
      this.customerFetched = !!this.customer?.email;
    },
  },
  computed: {
    subjectOptions(): Array<{ subjectTitle: string; instructions: string[] }> {
      const defaultOptions = ["メールアドレス変更", "退会希望", "その他のお問い合わせ"];

      if (this.customerFetched) {
        defaultOptions.unshift(
          "予約手配に関するメールが届かない",
          "天候不良で体験中止になったお客様",
        );
      } else {
        defaultOptions.unshift(
          "ログインできない",
          "チケットの期限について"
        );
      }

      return defaultOptions.map((subject) => ({
        subjectTitle: subject,
        instructions: this.subjects[subject],
      }));
    },
  },
});
