



























import Vue from "vue";
import CardDetail from "../Common/CardDetail.vue";
import CommonModalScreen from '../Common/CommonModalScreen.vue';
import DeleteButton from '../Common/DeleteButton.vue';
import PlusoneGiftPage from './PlusoneGiftPage.vue';
import { Item, SelectedSkus, SelectedSku } from "@/components/CartCommon/cart";
import { alertError } from "@/util/alert_errors";

export default Vue.extend({
  props: {
    itemIndex: Number as () => number,
    optionItem: {
      type: Object as () => SowExp.OptionItem
    },
    currentOptionItem: {
      type: Object as () => SelectedSku | null
    },
  },
  data() {
    return {
      isModalOpen: false,
      selectedSku: this.currentOptionItem,
    };
  },
  components: {
    CardDetail,
    CommonModalScreen,
    DeleteButton,
    PlusoneGiftPage,
  },
  computed: {
    modalName(): string {
      return `cart-modal-plusonegift-${this.itemIndex}-${this.optionItem.id}`;
    },
    item(): Item | null {
      return this.$store.state.items[this.itemIndex];
    },
    selectedSkus(): SelectedSkus {
      if (this.item!!.selectedOptions.skus) {
        return this.item!!.selectedOptions.skus;
      } else {
        return {};
      };
    },
    skuPrices() {
      return this.optionItem.skus.map((sku: SowExp.Sku) => { return sku.price});
    },
  },
  methods: {
    onClick() {
      // スクロール中にカードのクリック操作が起きないように
      // ItemSelectorでdraggingを制御
      if (window.dragging) {
        return;
      };

      this.isModalOpen = true;
    },
    onCancel() {
      this.selectedSku = this.currentOptionItem;
      this.isModalOpen = false;
    },
    onConfirm() {
      if (!this.selectedSku) {
        return;
      }

      // Sku更新
      const skus = this.selectedSkus;
      skus[this.selectedSku.optionItemId] = this.selectedSku;
      this.updateSkus(skus);
    },
    onDeleteOption() {
      if (this.selectedSku) {
        const skus = this.selectedSkus;
        delete skus[this.selectedSku.optionItemId];
        this.updateSkus(skus);
      };
    },
    async updateSkus(skus) {
      try {
        await this.$store
          .dispatch("updatePlusoneGift", {
            itemId: this.itemIndex,
            skus: skus,
          })
          .catch(() => {
            throw new Error("エラーが発生しました");
          });
        this.isModalOpen = false;
      } catch (e) {
        alertError(e);
      };
    },
    onSkuSelected(skuId: number) {
      if (this.selectedSku) {
        this.selectedSku.skuId = skuId;
      } else {
        this.selectedSku = {
          optionItemId: this.optionItem.id,
          skuId: skuId,
          quantity: 1,
        };
      };
    },
  },
});
