import Vue from "vue";
import AirbrakeClient from "airbrake-js";
import { showDialog } from "./show_dialog";

/**
 * グローバルな予期せぬエラー(プログラムで明示的にcatchしていないエラー）のハンドラを登録します
 * ハンドラは以下を行います
 * - ユーザーに対して予期せぬエラーが起きた旨を表示
 * - Airbrakeにエラー内容を送信
 * なお、このグローバルエラーハンドラはエラーの共通ハンドリングを行なうためのものではなく、
 * あくまでもハンドリングできてないエラーが起きた際のサポート用と捉えてください。
 *
 * ハンドリングにはVue.config.errorHandlerを用います
 *
 * [注意] 現時点でVue.config.errorHandlerが捕捉できるエラーはかなり限定的です
 * 例えば、v-onのハンドラやasyncメソッド内のエラーは捕捉できません。
 * https://github.com/vuejs/vue/issues/6953#issuecomment-340335435
 * https://qiita.com/clomie/items/73fa1e9f61e5b88826bc
 *
 * [捕捉] Vue2.6でv-onやasync内のエラーも捕捉できるようになりそうです
 * https://github.com/vuejs/vue/pull/8395
 */
export function registerGlobalErrorHandler() {
  // VueのerrorHandlerメソッドを用いて例外を捕捉します
  Vue.config.errorHandler = async (err, vm, info) => {
    notifyToAirbrake(err, info);
    showDialog("予期せぬエラーが発生しました");
  };
}

const airbrake = new AirbrakeClient({
  projectId: 1,
  projectKey:
    process.env.NODE_ENV === "production"
      ? "a58dd2c9dafd4da50229a97869323290"
      : "68120f1d50bbc65ff1fde65be74991f0",
  environment: process.env.NODE_ENV === "production" ? "production" : "staging",
  host: "https://sow-err.herokuapp.com",
  // airbrakeはscriptロード時に自動でwindow.onerrorハンドラを登録するようだが
  // 上記でハンドラは独自に登録しているのでこの自動登録は解除する
  // https://github.com/airbrake/airbrake-js#windowonerror
  ignoreWindowError: true
});

airbrake.addFilter(function(notice) {
  try {
    if (notice.errors[0].backtrace[0].file.indexOf("chunk-vendors") < 0) {
      return null;
    }
  }
  catch (e) {
    console.log(e);
  }

  return notice;
});

async function notifyToAirbrake(err: Error, info: string) {
  return airbrake.notify({
    error: convertToText(err),
    context: {
      component: "javascript(vue.js) error"
    },
    params: {
      info: info
    }
  });
}

// https://stackoverflow.com/questions/5612787/converting-an-object-to-a-string/18368918#18368918
function convertToText(obj) {
  //create an array that will later be joined into a string.
  let string: string[] = [];

  if (typeof obj == "object" && obj.join == undefined) {
    //is object

    string.push("{");
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        string.push(prop, ": ", convertToText(obj[prop]), ",");
      }
    }

    string.push("}");
  } else if (typeof obj == "object" && !(obj.join == undefined)) {
    //is array

    string.push("[");
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        string.push(convertToText(obj[prop]), ",");
      }
    }
    string.push("]");
  } else if (typeof obj == "function") {
    //is function

    string.push(obj.toString());
  } else {
    //all other values can be done with JSON.stringify

    string.push(JSON.stringify(obj));
  }

  return string.join("");
}
