import { AxiosError } from "axios";
import { showDialog } from "./show_dialog";

/**
 * エラーを画面へ表示する
 */
export function alertError(
  error: Error | AxiosError,
  title = "エラーが起きました"
) {
  const message = buildErrorMessage(error);
  showDialog(`${title}\n${message}`);
}

/**
 * エラーメッセージをビルドする
 */
function buildErrorMessage(e: Error | AxiosError): string {
  if ("response" in e) {
    return buildAxiosErrorMessage(e);
  } else {
    return e.message;
  }
}

/**
 * AxiosError用のエラーメッセージをビルドする
 */
function buildAxiosErrorMessage(e: AxiosError): string {
  // クライアントサイドでのAxiosエラー
  // ネットに繋がってない、サーバーが完全に落ちている、など
  //（net::ERR_CONNECTION_REFUSEDなど）
  if (e.response == null) {
    return e.message;
  }

  // 以下、サーバーサイドからエラーレスポンスが帰ってきた場合

  // エラーメッセージはerrorsプロパティに入ってくるものとする（サーバーサイドのルール）
  if (e.response.data.errors != null) {
    return e.response.data.errors.join("\n");
  }

  // エラーメッセージがサーバーサイドで明示的に指定されていない場合

  // 404, 500以上のときは各エラーページへ強制遷移
  if (e.response.status == 404) {
    window.location.href = "/404.html";
    return "";
  }

  if (e.response.status >= 500) {
    window.location.href = "/500.html";
    return "";
  }

  // 上記以外のstatusコード
  return "予期せぬエラーです";
}
