<template>
  <svg data-name="ギフトショッパー" xmlns="http://www.w3.org/2000/svg" width="23" height="23">
    <title>Gift Bag Icon</title>
    <defs>
      <clipPath id="a">
        <path data-name="長方形 2113" style="fill:none" d="M0 0h23v23H0z"/>
      </clipPath>
    </defs>
    <g data-name="グループ 46"><path data-name="パス 18" d="M2.012 21.492 3.623 9.081h3.138V6.738a5.308 5.308 0 0 1 .767-2.586 5.439 5.439 0 0 0-.744 2.58v2.35h9.754V6.775a5.541 5.541 0 0 0-.757-2.627 5.37 5.37 0 0 1 .779 2.626v2.307h3.14l1.622 12.412z" transform="translate(-.161 -.332)" style="fill:#337ab0"/><path data-name="パス 19" d="m18.81 9.749 1.382 10.571H2.969L4.35 9.749h14.46zM11.58 1a5.769 5.769 0 0 0-4.171 1.5 5.916 5.916 0 0 0-1.648 3.986v1.423H2.735L1.008 21.122a.924.924 0 0 0 .924 1.038h19.3a.924.924 0 0 0 .924-1.038l-1.73-13.213H17.4V6.535A5.981 5.981 0 0 0 15.74 2.5 5.75 5.75 0 0 0 11.58 1M7.623 7.909V6.48a4.2 4.2 0 0 1 1.121-2.741 3.953 3.953 0 0 1 2.836-.957 3.932 3.932 0 0 1 2.825.956 4.258 4.258 0 0 1 1.133 2.786v1.385z" transform="translate(-.08 -.08)" style="fill:#337ab0"/>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'GiftBagIcon',
  };
</script>