import Dialog from "./Dialog.vue";
import RestrictionDialog from "./RestrictionDialog.vue";

// widnow.alertの代用
// window.alertはvue-router, safariでバグがあるので全体的に使わない方がよい
// https://github.com/vuejs/vue-router/issues/2316
interface IShowDialogParams {
  yesLabel?: string | null;
  yesHandler?: () => void | null;
  noLabel?: string | null;
  noHandler?: () => void | null;
  dialogName?: string | null;
  title?: string | null;
  description?: string | null;
  yesComment?: string | null;
  noComment?: string | null;
}
let dialogVm: any;
export function showDialog(message: string, params?: IShowDialogParams) {
  // dialogインスタンスがすでにメモリに存在しており、開いているなら閉じる
  if (dialogVm != null && dialogVm.isOpen) {
    dialogVm.close();
  };
  if(params && params.dialogName === "restriction") {
    dialogVm = new RestrictionDialog({
      el: document.createElement("div"),
      propsData: {
        message: message,
        title: params ? params.title : null,
        description: params ? params.description : null,
        yesLabel: params ? params.yesLabel : null,
        yesComment: params ? params.yesComment : null,
        yesHandler: params ? params.yesHandler : null,
        noLabel: params ? params.noLabel : "閉じる",
        noComment: params ? params.noComment : null,
        noHandler: params ? params.noHandler : null,
      }
    })
  } else {
    dialogVm = new Dialog({
      el: document.createElement("div"),
      propsData: {
        message: message,
        yesLabel: params ? params.yesLabel : null,
        yesHandler: params ? params.yesHandler : null,
        noLabel: params ? params.noLabel : "閉じる"
      }
    })
  };
  dialogVm.show();
}
