







































import Vue from "vue";

export default Vue.extend({
  props: {
    typeId: Number,
    customOmotegaki: String,
    nairePattern: Number,
    fullName1: String,
    fullName2: String,
    fullName3: String,
    companyName: String,
    lastName: String,
    firstName1: String,
    firstName2: String,
    firstName3: String,
    kana: String
  },
  data() {
    return {};
  }
});
