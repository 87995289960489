
































import Vue from "vue";
import ItemDetail from "../Common/ItemDetail.vue";
import MessageCardDescription from "./MessageCardDescription.vue";
import MessageCardPlain from "./MessageCardPlain.vue";
import MessageCardCelebration from "./MessageCardCelebration.vue";
import { MessageCardCelebrationForm } from "./MessageCardCelebration.vue";
import { SelectedMessageCard } from "@/components/CartCommon/cart";
import { PLAIN_CARD_KIND, CELEBRATION_CARD_KIND } from "@/api/cart";

export default Vue.extend({
  props: {
    messageCard: Object,
    initialMessageCard: Object as () => SelectedMessageCard,
  },
  components: {
    ItemDetail,
    MessageCardDescription,
    MessageCardPlain,
    MessageCardCelebration,
  },
  computed: {
    initialPlainMessage(): string | null {
      if (this.initialMessageCard.kind == PLAIN_CARD_KIND && this.initialMessageCard.id == this.messageCard.id) {
        return this.initialMessageCard.message;
      } else {
        return "";
      };
    },
    initialCelebrationFormData(): MessageCardCelebrationForm {
      return {
        lastName: this.initialMessageCard.lastName || "",
        firstName: this.initialMessageCard.firstName || "",
        lastNameKana: this.initialMessageCard.lastNameKana || "",
        firstNameKana: this.initialMessageCard.firstNameKana || "",
        birthYear: this.initialMessageCard.birthYear || null,
        birthMonth: this.initialMessageCard.birthMonth || null,
        birthDate: this.initialMessageCard.birthDate || null,
        weight: this.initialMessageCard.weight || null,
        height: this.initialMessageCard.height || null,
        message:
          this.initialMessageCard.kind == CELEBRATION_CARD_KIND
            ? this.initialMessageCard.message || ""
            : ""
      };
    },
  },
  methods: {
    isPlainCard(messageCard: SelectedMessageCard): boolean {
      return (messageCard.kind == PLAIN_CARD_KIND);
    },
    isCelebrationCard(messageCard: SelectedMessageCard): boolean {
      return (messageCard.kind == CELEBRATION_CARD_KIND);
    },
    onMessageCardPlainChanged(message: string) {
      const messageCard = {
        id: this.messageCard.id,
        message: message,
        kind: PLAIN_CARD_KIND,
        lastName: null,
        firstName: null,
        lastNameKana: null,
        firstNameKana: null,
        birthYear: null,
        birthMonth: null,
        birthDate: null,
        weight: null,
        height: null,
      };

      this.$emit("cardChanged", messageCard);
    },
    onMessageCardCelebrationChanged(m: SelectedMessageCard) {
      const messageCard = {
        id: this.messageCard.id,
        message: m.message,
        kind: CELEBRATION_CARD_KIND,
        lastName: m.lastName,
        firstName: m.firstName,
        lastNameKana: m.lastNameKana,
        firstNameKana: m.firstNameKana,
        birthYear: m.birthYear,
        birthMonth: m.birthMonth,
        birthDate: m.birthDate,
        weight: m.weight,
        height: m.height,
      };

      this.$emit("cardChanged", messageCard);
    },
  },
});
