













































































import Vue from "vue";
import Catalog from "./Catalog.vue";
import SelectedOptionList from "./SelectedOptionList.vue";
import WrappingSelector from "./Wrapping/WrappingSelector.vue";
import NoshiSelector from "./Noshi/NoshiSelector.vue";
import MessageCardSelector from "./MessageCard/MessageCardSelector.vue";
import GiftBagSelector from "./GiftBag/GiftBagSelector.vue";
import PlusoneGiftSelector from "./PlusoneGift/PlusoneGiftSelector.vue";
import { MessageCardCelebrationForm } from "./MessageCard/MessageCardCelebration.vue";
import {
  Item,
  CommonMasterData,
  SelectedWrapping,
  SelectedNoshi,
  SelectedMessageCard,
  SelectedGiftBag,
  SelectedSku,
  SelectedSkus,
} from "@/components/CartCommon/cart";
import { PLAIN_CARD_KIND, CELEBRATION_CARD_KIND } from "@/api/cart";
import { alertError } from "@/util/alert_errors";
import _ from "lodash";

export default Vue.extend({
  props: {
    item: Object as () => Item,
    commonMasterData: Object as () => CommonMasterData,
  },
  components: {
    Catalog,
    SelectedOptionList,
    WrappingSelector,
    NoshiSelector,
    MessageCardSelector,
    GiftBagSelector,
    PlusoneGiftSelector,
  },
  computed: {
    selectedWrapping(): SowExp.Wrapping | null {
      const selectedW = this.item.selectedOptions.wrapping;
      return selectedW
        ? this.item.wrappings.find(w => w.id == selectedW.id)!!
        : null;
    },
    selectedMessageCard(): SowExp.MessageCard | null {
      const selectedM = this.item.selectedOptions.messageCard;
      return selectedM
        ? this.commonMasterData.messageCards.find(m => m.id == selectedM.id)!!
        : null;
    },
    selectedNoshi(): SowExp.Noshi | null {
      const selectedN = this.item.selectedOptions.noshi;
      return selectedN ? { id: 1, name: "のしあり", price: 0 } : null;
    },
    selectedGiftBag(): SowExp.GiftBag | null {
      const selectedG = this.item.selectedOptions.giftBag;
      return selectedG
        ? this.commonMasterData.giftBags.find(g => g.id == selectedG.id)!!
        : null;
    },
    selectedSkus(): {
      sku: SowExp.Sku;
      optionItem: SowExp.OptionItem;
      quantity: number;
    }[] {
      // 現在選択されているskuIdのリスト
      const selectedSkuIds = _.map(
        this.item.selectedOptions.skus,
        (s: SelectedSku) => {
          return s.skuId;
        },
      );

      const selectedSkuList: {
        sku: SowExp.Sku;
        optionItem: SowExp.OptionItem;
        quantity: number;
      }[] = [];

      this.item.optionItems.forEach(optionItem => {
        const selectedSku = this.item.selectedOptions.skus[optionItem.id];
        if (selectedSku == null) return; // continue

        const selectedSkuMaster = optionItem.skus.find(
          s => s.id == selectedSku.skuId,
        );
        if (selectedSkuMaster == null) return; // continue

        const res = {
          sku: selectedSkuMaster,
          optionItem: optionItem,
          quantity: selectedSku.quantity,
        };

        selectedSkuList.push(res);
      });

      return selectedSkuList;
    },
    initialMessageCard(): SelectedMessageCard {
      return this.item!!.selectedOptions.messageCard;
    },
    initialPlainMessage(): string | null {
      return this.initialMessageCard.kind == PLAIN_CARD_KIND
        ? this.initialMessageCard.message
        : "";
    },
    initialCelebrationFormData(): MessageCardCelebrationForm {
      return {
        lastName: this.initialMessageCard.lastName || "",
        firstName: this.initialMessageCard.firstName || "",
        lastNameKana: this.initialMessageCard.lastNameKana || "",
        firstNameKana: this.initialMessageCard.firstNameKana || "",
        birthYear: this.initialMessageCard.birthYear || null,
        birthMonth: this.initialMessageCard.birthMonth || null,
        birthDate: this.initialMessageCard.birthDate || null,
        weight: this.initialMessageCard.weight || null,
        height: this.initialMessageCard.height || null,
        message:
          this.initialMessageCard.kind == CELEBRATION_CARD_KIND
            ? this.initialMessageCard.message || ""
            : "",
      };
    },
  },
  methods: {
    onItemDelete(): void {
      this.$emit("itemDelete", this.item);
    },
    onCatalogQuantityChanged(quantity: number): void {
      this.$emit("catalogQuantityChanged", this.item, quantity);
    },
    onSkuQuantityChanged(selectedSku: SelectedSku): void {
      console.log("onSkuQuantityChanged");
      this.$emit("skuQuantityChanged", this.item.index, selectedSku);
    },
  },
});
