

























































import Vue from "vue";
import { Coupon } from "@/components/CartCommon/cart";

export default Vue.extend({
  props: {
    coupons: Array as () => Coupon[],
  },
  data() {
    return {
      selectedCoupon: (this.coupons.find(coupon => coupon.selected) || {}) as Coupon, // initialize selected coupon
    };
  },
  watch: {
    selectedCoupon(): void {
      this.toggleLocalSelectedAttr(); // update selected attributes locally, required for proper behaviour of radio buttons
      this.$emit("updateSelectedCoupon", this.selectedCoupon); // update data in cart
    },
  },
  methods: {
    toggleLocalSelectedAttr(): void {
      this.coupons.forEach(coupon => (coupon.selected = false));
      this.selectedCoupon.selected = true;
    },

    isSelected(coupon): boolean {
      return this.selectedCoupon.couponId === coupon.couponId;
    },
  },
  computed: {
    couponSelected(): boolean {
      return this.selectedCoupon.couponId !== undefined;
    },
  },
});
