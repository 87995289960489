


















import Vue from "vue";
import Flickity from "vue-flickity";

export default Vue.extend({
  props: {
    name: String as () => string,
    title: String as () => string,
    hasMultipleCards: Boolean as () => boolean | false,
  },
  data() {
    return {
      areaVisible: false,
    };
  },
  components: {
    Flickity,
  },
  computed: {
    flickityOptions(): any {
      return {
        draggable: true,
        accessibility: false,
        contain: true,
        prevNextButtons: this.hasMultipleCards,
        pageDots: false,
        cellAlign: "left",
        // スクロール中にカードのクリック操作が起きないように
        on: {
          dragStart: function() {
            window.dragging = true;
          },
          dragEnd: function() {
            setTimeout(function() {
              window.dragging = false;
            }, 200);
          },
        },
      };
    },
  },
  methods: {
    onArrowButtonClicked() {
      this.areaVisible = !this.areaVisible;
    },
  }
});
