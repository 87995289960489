


















import Vue from "vue";
import ItemSelector from "../Common/ItemSelector.vue";
import WrappingCard from "./WrappingCard.vue";
import { Item, SelectedWrapping } from "@/components/CartCommon/cart";
import WrappingIcon from "../Common/Icons/WrappingIcon.vue";

export default Vue.extend({
  props: {
    itemIndex: Number as () => number,
  },
  components: {
    ItemSelector,
    WrappingCard,
    WrappingIcon,
  },
  computed: {
    item(): Item | null {
      return this.$store.state.items[this.itemIndex];
    },
    selectedWrapping(): SelectedWrapping | null {
      return this.item!!.selectedOptions.wrapping;
    },
    wrappings(): Array<SelectedWrapping> | null {
      return this.item!!.wrappings;
    },
    hasMultipleCards(): boolean {
      return this.wrappings!!.length >= 3;
    },
  },
  methods: {
    currentWrapping(wrappingId: number) {
      return this.selectedWrapping!!.id == wrappingId;
    },
  },
});
