
















import Vue from "vue";

export default Vue.extend({
  props: {
    name: String,
    price: Number,
    prices: Array,
    imageSrc: String,
    currentCard: Boolean,
  },
  computed: {
    kara(): string {
      const unique_prices = Array.from(new Set(this.prices));
      return unique_prices.length > 1 ? "～" : "";
    },
  },
});
