/**
 * google analyticsにイベントを送信します
 * rails側（app/views/layouts/common_parts/_google_analytics.html.erb）で
 * 事前にgaインスタンスを読み込んでおく必要があります
 */
export function sendGAEvent(category: string, action: string, label: string) {
  const ga = (window as any).ga;

  if (ga == null) {
    console.error("google analyticsのjsインスタンスが読み込まれていません");
    return;
  }

  ga("send", "event", category, action, label);
}
