import axios from "axios";
import { getCsrfToken } from "@/util/csrf";

const csrfToken = getCsrfToken();
if (csrfToken != null) {
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
}

export const api = axios.create({
  baseURL: "/",
  timeout: 0 // timeout設定なし（サーバーサイドに任せる）
});

export const ADMIN_PATH = '/admin_kanri';
