
















import { String } from "lodash";
import Vue from "vue";
export default Vue.extend({
  props: {
    message: {
      type: String as () => string
    },
    title: {
      type: String as () => string
    },
    description: {
      type: String as () => string
    },
    yesLabel: {
      type: String as () => string,
      default: "確定"
    },
    yesComment: {
      type: String as () => string
    },
    yesHandler: {
      type: Function
    },
    noLabel: {
      type: String as () => string,
      required: true
    },
    noComment: {
      type: String as () => string
    },
    noHandler: {
      type: Function
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  mounted() {
    // vue-routerで制御している画面でブラウザの「戻る」を押下した際にダイアログがちゃんと閉じるように
    // hashchangeイベントのハンドラを登録しておく
    (this as any).$nextTick(() => {
      window.addEventListener("hashchange", (this as any).close);
    });
  },
  beforeDestroy() {
    // vmインスタンス破棄する際には、windowに登録したハンドラも破棄
    window.removeEventListener("hashchange", (this as any).close);
  },
  methods: {
    // ダイアログを開く（showDialogメソッドから呼ばれる想定）
    show() {
      (this as any).isOpen = true;
      // body直下にdomエレメントを注入
      document.body.appendChild(this.$el);
    },
    // ダイアログを閉じる
    close() {
      (this as any).isOpen = false;
      // bodyからdomエレメントを削除
      document.body.removeChild((this as any).$el);
      // このvmインスタンスを破棄
      (this as any).$destroy();
    },
    // 閉じるボタンのハンドラ
    onCloseButtonClicked() {
      (this as any).close();
    }
  }
});
