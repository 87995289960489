<template>
   <svg data-name="メッセージカード" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
    <title>Message Card Icon</title>
    <defs>
      <clipPath id="a">
        <path data-name="長方形 2112" style="fill:none" d="M0 0h20v20H0z"/>
      </clipPath>
    </defs>
    <g data-name="グループ 45">
      <g data-name="グループ 44">
        <path data-name="パス 17" d="M10.517.162a.907.907 0 0 0-1.034 0L0 6.808v12.368A.847.847 0 0 0 .87 20h18.26a.848.848 0 0 0 .87-.824V6.808zm-.018 12.5a.907.907 0 0 1-1 0L1.3 7.22h17.4z" style="fill:#337ab0"/>
      </g>
    </g>
   </svg>
</template>
  
<script>
  export default {
    name: 'MessageCardIcon',
  };
</script>
  