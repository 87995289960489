











import Vue from "vue";
export default Vue.extend({
  props: {
    message: {
      type: String as () => string | null
    }
  },
  methods: {
    // メッセージの中身が枠の高さを超えているかどうか
    // このコンポーネントの外から呼び出される想定
    async checkHeightExceeded(): Promise<boolean> {
      await this.$nextTick();
      const clientHeight = (this.$refs.messageAreaRef as any).clientHeight;
      const scrollHeight = (this.$refs.messageAreaRef as any).scrollHeight;
      return scrollHeight > clientHeight;
    }
  }
});
