<template>
    <svg data-name="ラッピング" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
      <title>Wrapping Icon</title>
      <defs>
        <clipPath id="a">
          <path data-name="長方形 2117" style="fill:none" d="M0 0h20v20H0z"/>
        </clipPath>
      </defs>
      <g data-name="グループ 66">
        <path data-name="パス 25" d="M7.466 0H1.739A1.739 1.739 0 0 0 0 1.739v5.727z" style="fill:#337ab0"/>
        <path data-name="パス 26" d="M14.414 21.88h5.727a1.739 1.739 0 0 0 1.739-1.739v-5.727z" transform="translate(-1.88 -1.88)" style="fill:#337ab0"/>
        <path data-name="パス 27" d="M20 9.205V1.739A1.739 1.739 0 0 0 18.261 0h-7.466L0 10.795v7.466A1.739 1.739 0 0 0 1.739 20h7.466z" style="fill:#337ab0"/>
      </g>
    </svg>
</template>

<script>
  export default {
    name: 'WrappingIcon',
  };
</script>