import { api } from "@/api/apiclient";
import {
  AddToCartParam,
  SelectedSku,
  SelectedNoshi,
  SelectedMessageCard,
  SelectedSkus,
  SelectedGiftBag,
  Coupon,
} from "@/components/CartCommon/cart";
import _ from "lodash";

export const NO_CARD_KIND = 0;
export const PLAIN_CARD_KIND = 1;
export const CELEBRATION_CARD_KIND = 2;

/**
 * カートにアイテムを追加
 */
export const addToCart = (p: AddToCartParam) => {
  const params = {} as any;
  // カタログ
  params["catalog_id"] = p.catalogId;
  // 数量
  params["quantity"] = p.quantity;

  // ラッピング
  if (p.wrapping) {
    params["wrapping_id"] = p.wrapping.id;
  }

  // プリセレクトされるskuがある場合にはセット
  if (p.preSelectedPanelSku) {
    const sku = p.preSelectedPanelSku;
    params[`sku_${sku.itemId}`] = sku.skuId;
  }
  if (p.preSelectedCoffeeSku) {
    const sku = p.preSelectedCoffeeSku;
    params[`sku_${sku.itemId}`] = sku.skuId;
  }

  return api.post("/cart2018/add_to_cart", params);
};

/**
 * カート内容を取得
 */
export const fetchCart = () => {
  return api.get("/cart2018/get_cart");
};

/**
 * カートからアイテムを削除
 */
export const deleteItem = (itemId: number) => {
  const params = {} as any;
  params["item_id"] = itemId;
  return api.post("/cart2018/delete_item", params);
};

/**
 * カタログの数量を更新
 */
export const updateCatalogQuantity = (
  itemId: number,
  catalogId: number,
  quantity: number
) => {
  const params = {} as any;
  params["item_id"] = itemId;
  params["catalog_id"] = catalogId;
  params["quantity"] = quantity;
  return api.post("/cart2018/update_catalog_quantity", params);
};

/**
 * ラッピングを更新
 */
export const updateWrapping = (
  itemId: number,
  wrappingId: number,
) => {
  const params = {} as any;
  params["item_id"] = itemId;

  // ラッピング
  params["wrapping_id"] = wrappingId;

  return api.post("/cart2018/update_wrapping", params);
};

/**
 * のしを更新
 */
export const updateNoshi = (
  itemId: number,
  noshi: SelectedNoshi | null
) => {
  const params = {} as any;
  params["item_id"] = itemId;

  // のし
  if (noshi != null) {
    params["noshi"] = 1;
    params["noshi_objective"] = noshi.objective;
    params["noshi_use"] = noshi.use;
    params["noshi_id"] = noshi.typeId;

    params["noshi_name"] = noshi.typeName;

    params["noshi_custom_omotegaki"] = noshi.customOmotegaki;

    params["noshi_naire_pattern"] = noshi.nairePattern;
    params["noshi_company_name"] = noshi.companyName;
    params["noshi_full_name1"] = noshi.fullName1;
    params["noshi_full_name2"] = noshi.fullName2;
    params["noshi_full_name3"] = noshi.fullName3;
    params["noshi_last_name"] = noshi.lastName;
    params["noshi_first_name1"] = noshi.firstName1;
    params["noshi_first_name2"] = noshi.firstName2;
    params["noshi_first_name3"] = noshi.firstName3;
    params["noshi_kana"] = noshi.kana;

    if (noshi.isUseMeigi) {
      params["meigi"] = 1;
      params["noshi_naire"] = noshi.naire;
    } else {
      params["meigi"] = 0;
    }
  } else {
    params["noshi"] = 0;
  }

  return api.post("/cart2018/update_noshi", params);
};

/**
 * メッセージカードを更新
 */
export const updateMessageCard = (
  itemId: number,
  messageCard: SelectedMessageCard | null
) => {
  const params = {} as any;
  params["item_id"] = itemId;

  if (messageCard == null) {
    params["message_card_id"] = 0;
    params["message_card_kind"] = NO_CARD_KIND;
  } else if (messageCard.kind == NO_CARD_KIND) {
    params["message_card_id"] = 0;
    params["message_card_kind"] = NO_CARD_KIND;
  } else if (messageCard.kind == PLAIN_CARD_KIND) {
    params["message_card_id"] = messageCard.id;
    params["message_card_kind"] = messageCard.kind;
    params["message"] = messageCard.message;
  } else if (messageCard.kind == CELEBRATION_CARD_KIND) {
    params["message_card_id"] = messageCard.id;
    params["message_card_kind"] = messageCard.kind;
    params["last_name"] = messageCard.lastName;
    params["first_name"] = messageCard.firstName;
    params["last_name_kana"] = messageCard.lastNameKana;
    params["first_name_kana"] = messageCard.firstNameKana;
    if (
      messageCard.birthYear != null &&
      messageCard.birthMonth != null &&
      messageCard.birthDate != null
    ) {
      params["birthday"] = `${messageCard.birthYear}-${
        messageCard.birthMonth
      }-${messageCard.birthDate}`;
    }
    params["weight"] = messageCard.weight;
    params["height"] = messageCard.height;
    params["message"] = messageCard.message;
  } else {
    // ここに来るのはバグの場合
    throw new Error("不正なメッセージカード形式です");
  }

  return api.post("/cart2018/update_message_card", params);
};

/**
 *  GiftShopperを更新
 */
export const updateGiftBag= (
  itemId: number,
  giftBag: SelectedGiftBag | null,
) => {
  const params = {} as any;
  params["item_id"] = itemId;

  // ギフトバッグ
  if (giftBag != null) {
    params["gift_bag_id"] = giftBag.id;
  } else {
    params["gift_bag_id"] = 0;
  }

  return api.post("/cart2018/update_gift_shopper", params);
};


/**
 *  PlusoneGiftを更新
 */
export const updatePlusoneGift = (
  itemId: number,
  skus: SelectedSkus
) => {
  const params = {} as any;
  params["item_id"] = itemId;

  // sku
  params["skus"] = _.map(skus, (sku: SelectedSku) => {
    return {
      option_item_id: sku.optionItemId,
      sku_id: sku.skuId,
      quantity: sku.quantity
    };
  });

  return api.post("/cart2018/update_plusone_gift", params);
};

/**
 * skuの個数を更新
 */
export const updateSkuQuantity = (
  itemIndex: number,
  selectedSku: SelectedSku
) => {
  const params = {
    item_id: itemIndex,
    option_item_id: selectedSku.optionItemId,
    sku_id: selectedSku.skuId,
    quantity: selectedSku.quantity
  };

  return api.post("/cart2018/update_sku_quantity", params);
};

export const updateSelectedCoupon = (coupon: Coupon) => {
  const params = { coupon_id: coupon.couponId }; // nil if no coupon selected
  return api.post("/cart2018/update_selected_coupon", params);
};
