

























































































































































































































import Vue from "vue";
import NoshiPreview from "./NoshiPreview.vue";
import { SelectedNoshi } from "@/components/CartCommon/cart";

export default Vue.extend({
  props: {
    noshiObjectives: Array as () => SowExp.NoshiObjective[],
    initialNoshiDetail: Object as () => SelectedNoshi,
  },
  components: {
    NoshiPreview,
  },
  data() {
    // todo: initialNoshiDetailによる制御
    const objName = this.initialNoshiDetail
      ? this.initialNoshiDetail.objective
        ? this.initialNoshiDetail.objective
        : "お祝い"
      : "お祝い";

    const useName = this.initialNoshiDetail
      ? this.initialNoshiDetail.use
        ? this.initialNoshiDetail.use
        : "結婚祝い"
      : "結婚祝い";

    const initialObj = this.noshiObjectives.find(o => o.name == objName)!!;
    const initialUse = initialObj.noshiUses.find(u => u.name == useName)!!;

    return {
      objectiveOption: this.noshiObjectives.map(o => o.name),
      useOption: initialObj.noshiUses.map(u => u.name),
      typeOption: initialUse.noshiTypes,
      noshiForm: { ...this.initialNoshiDetail } as SelectedNoshi,
      isSp: false,
    };
  },
  created() {
    const width = innerWidth;
    if (width <= 768) {
      this.isSp = true;
    }
  },
  watch: {
    "noshiForm.objective"() {
      this.resetUse();
    },
    "noshiForm.use"() {
      this.resetName();
    },
    "noshiForm.nairePattern"() {
      this.clearValues();
    },

    "noshiForm.typeId"() {
      this.noshiForm.customOmotegaki = null;
    },
    noshiForm: {
      handler() {
        this.$emit("noshiDetailCanged", { ...this.noshiForm } as SelectedNoshi);
      },
      deep: true,
    },
  },
  computed: {
    naireCount(): number {
      if (this.noshiForm.naire == null) {
        return 0;
      } else {
        return this.noshiForm.naire.length;
      }
    },
  },
  methods: {
    // 現在選択中の「objective」によってuseの選択肢と選択状態を変える
    resetUse() {
      const objName = this.noshiForm.objective!!.trim();
      const obj = this.noshiObjectives.find(o => o.name == objName)!!;

      this.useOption = obj.noshiUses.map(u => u.name);
      this.$nextTick(() => {
        this.noshiForm.use = this.useOption[0];
      });
    },

    // 現在選択中の「use」によってnameの選択肢と選択状態を変える
    resetName() {
      if (!this.noshiForm.use) {
        return;
      }

      const objName = this.noshiForm.objective!!.trim();
      const useName = this.noshiForm.use!!.trim();
      const obj = this.noshiObjectives.find(o => o.name == objName)!!;
      const use = obj.noshiUses.find(u => u.name == useName)!!;

      this.typeOption = use.noshiTypes;
      if (this.typeOption.length > 0) {
        this.noshiForm.typeId = this.typeOption[0].id;
        this.noshiForm.typeName = this.typeOption[0].name;
      } else {
        this.noshiForm.typeId = null;
      }
    },
    clearValues() {
      this.noshiForm.fullName1 = null;
      this.noshiForm.fullName2 = null;
      this.noshiForm.fullName3 = null;
      this.noshiForm.companyName = null;
      this.noshiForm.lastName = null;
      this.noshiForm.firstName1 = null;
      this.noshiForm.firstName2 = null;
      this.noshiForm.firstName3 = null;
      this.noshiForm.kana = null;
    },
  },
});
