












































import Vue from "vue";
import { MessageCardCelebrationForm } from "./MessageCardCelebration.vue";

export default Vue.extend({
  props: {
    form: Object as () => MessageCardCelebrationForm
  },
  computed: {
    isBirthDayExists(): boolean {
      return (
        this.form.birthYear != null &&
        this.form.birthMonth != null &&
        this.form.birthDate != null
      );
    }
  },
  methods: {
    // メッセージの中身が枠の高さを超えているかどうか
    // このコンポーネントの外から呼び出される想定
    async checkHeightExceeded(): Promise<boolean> {
      await this.$nextTick();
      const clientHeight = (this.$refs.messageAreaRef as any).clientHeight;
      const scrollHeight = (this.$refs.messageAreaRef as any).scrollHeight;
      return scrollHeight > clientHeight;
    }
  }
});
