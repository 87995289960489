























import Vue from "vue";
import ItemDetail from "./ItemDetail.vue";

export default Vue.extend({
  props: {
    card: Object,
    images: Array,
    description: String,
  },

  components: {
    ItemDetail,
  },
});
