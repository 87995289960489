
























import Vue from "vue";
import CardDetail from "../Common/CardDetail.vue";
import CommonModalScreen from '../Common/CommonModalScreen.vue';
import ItemPage from '../Common/ItemPage.vue';
import { SelectedWrapping } from "@/components/CartCommon/cart";
import { alertError } from "@/util/alert_errors";

export default Vue.extend({
  props: {
    itemIndex: Number as () => number,
    wrapping: Object as () => SelectedWrapping,
    currentWrapping: Boolean,
  },
  data() {
    return {
      isModalOpen: false,
      modalTransition: { name: "modal-transition" }
    };
  },
  components: {
    CardDetail,
    CommonModalScreen,
    ItemPage,
  },
  computed: {
    modalName(): string {
      return `cart-modal-wrapping-${this.itemIndex}-${this.wrapping.id}`;
    },
  },
  methods: {
    onClick() {
      // スクロール中にカードのクリック操作が起きないように
      // ItemSelectorでdraggingを制御
      if (window.dragging) {
        return;
      };

      this.isModalOpen = true;
    },
    onCancel() {
      this.isModalOpen = false;
    },
    async onConfirm() {

      try {
        await this.$store
          .dispatch("updateWrapping", {
            itemId: this.itemIndex,
            wrapping: this.wrapping,
          })
          .catch(() => {
            throw new Error("エラーが発生しました");
          });
        this.isModalOpen = false;
      } catch (e) {
        alertError(e);
      }
    },

    images() {
      return [
        this.wrapping.sampleImage1Url,
        this.wrapping.sampleImage2Url,
        this.wrapping.sampleImage3Url];
    },
  },
});
