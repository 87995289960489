




























import Vue from "vue";
import CardDetail from "../Common/CardDetail.vue";
import CommonModalScreen from "../Common/CommonModalScreen.vue";
import DeleteButton from '../Common/DeleteButton.vue';
import MessageCardPage from "./MessageCardPage.vue";
import { Item, SelectedMessageCard } from "@/components/CartCommon/cart";
import { alertError } from "@/util/alert_errors";
import { showDialog } from "@/util/show_dialog";
import { PLAIN_CARD_KIND, CELEBRATION_CARD_KIND } from "@/api/cart";

export default Vue.extend({
  props: {
    itemIndex: Number as () => number,
    messageCard: Object,
    currentMessageCard: Boolean,
  },
  data() {
    return {
      isModalOpen: false,
      dirty: false,
      messageCardData: null as SelectedMessageCard | null,
    };
  },
  components: {
    CardDetail,
    CommonModalScreen,
    DeleteButton,
    MessageCardPage,
  },
  created() {
    if (this.initialMessageCard.id == this.messageCard.id) {
      this.messageCardData = this.initialMessageCard;
    };
  },
  computed: {
    modalName(): string {
      return `cart-modal-messagecard-${this.itemIndex}-${this.messageCard.id}`;
    },
    item(): Item | null {
      return this.$store.state.items[this.itemIndex];
    },
    initialMessageCard(): SelectedMessageCard {
      return this.item!!.selectedOptions.messageCard;
    },
  },
  methods: {
    isPlainCard(messageCard: SelectedMessageCard): boolean {
      return (messageCard.kind == PLAIN_CARD_KIND);
    },
    isCelebrationCard(messageCard: SelectedMessageCard): boolean {
      return (messageCard.kind == CELEBRATION_CARD_KIND);
    },
    onClick() {
      // スクロール中にカードのクリック操作が起きないように
      // ItemSelectorでdraggingを制御
      if (window.dragging) {
        return;
      };

      this.isModalOpen = true;
      this.dirty = false;

      // 以下の条件のときには、一時保存のmessageを初期化
      // ・一時保存したデータがnullでない（同じIDで初期値が設定されている）とき
      // ・Vuexに保存しているIDとカードを開くIDが異なるとき
      if (this.messageCardData) {
        if (this.messageCard.id != this.initialMessageCard.id) {
          this.messageCardData.message = "";
        };
      };
    },
    onCancel() {
      // 一度でもメッセージカードの選択肢を変更したら(dirty=true)
      //「キャンセル」を押下した際にユーザーに対して注意を促す
      if (this.dirty) {
        showDialog("編集内容を破棄しますか？", {
          yesLabel: "破棄する",
          yesHandler: () => {
            // 同じIDのときだけ保存した値をセット、それ以外はnullにセット
            if (this.messageCard.id == this.initialMessageCard.id) {
              this.messageCardData = this.initialMessageCard;
            } else {
              this.messageCardData = null;
            };

            this.dirty = false;
            this.isModalOpen = false;
          },
          noLabel: "続ける"
        });
      } else {
        this.isModalOpen = false;
      }
    },
    async onConfirm() {
      if (!this.validate()) {
        return false;
      };

      this.updateMessageCard(this.messageCardData);
    },
    async onDeleteOption() {
      this.updateMessageCard(null);
    },
    async updateMessageCard(messageCardData) {
      this.dirty = false;

      try {
        await this.$store.dispatch("updateMessageCard", {
          itemId: this.itemIndex,
          messageCard: messageCardData,
        });
        this.isModalOpen = false;
      } catch (e) {
        alertError(e);
      };
    },
    validate(): boolean {
      let errors: string[] = [];

      if (this.isPlainCard(this.messageCard)) {
        errors = this.validatePlainMessage(this.messageCardData);
      } else if (this.isCelebrationCard(this.messageCard)) {
        errors = this.validateMessageCardCelebration(this.messageCardData);
      };
      this.emojiErr(this.messageCardData, errors);

      if (errors.length >= 1) {
        showDialog("入力内容にエラーがあります\n\n" + errors.join("\n"));
        return false;
      } else {
        return true;
      }
    },
    validatePlainMessage(selectedMessageCard: SelectedMessageCard | null): string[] {
      const errors: string[] = [];
      if (!selectedMessageCard || !selectedMessageCard.message) {
        errors.push("1文字以上のメッセージを入力してください");
        return errors;
      }
      if (selectedMessageCard.message.length > 200) {
        errors.push("メッセージは200文字以下にしてください");
      }
      return errors;
    },
    validateMessageCardCelebration(
      m: SelectedMessageCard | null
    ): string[] {
      const errors: string[] = [];
      if (!m?.lastName) {
        errors.push("姓を入力してください。");
      }
      if (!m?.firstName) {
        errors.push("名を入力してください。");
      }
      if (!m?.lastNameKana) {
        errors.push("カナの姓を入力してください。");
      }
      if (!m?.firstNameKana) {
        errors.push("カナの名を入力してください。");
      }
      if (!m?.message) {
        errors.push("メッセージ本文を入力してください。");
      } else {
        if (m.message.length > 60) {
          errors.push("メッセージは60文字以下にしてください");
        }
      }
      if (m && !this.isBirthDayValid(m)) {
        errors.push("生年月日の入力が不完全です");
      }
      return errors;
    },
    isBirthDayValid(m: SelectedMessageCard): boolean {
      if (m.birthYear == null && m.birthMonth == null && m.birthDate == null) {
        return true;
      }

      if (m.birthYear != null && m.birthMonth != null && m.birthDate != null) {
        return true;
      }

      return false;
    },
    detectEmoji(k) {
      const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

      if (typeof(k) == 'string') {
        if (k.match(regex)) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    emojiErr(messageCardData, errors) {
      for(const k in messageCardData) {
        console.log(k, messageCardData[k]);
        if (this.detectEmoji(messageCardData[k])) {
          return errors.push(`使用できない文字が含まれています`);
        };
      }

    },
    onCardChanged(messageCard: SelectedMessageCard) {
      this.dirty = true;
      this.messageCardData = messageCard;
    },
  },
});
