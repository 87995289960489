

















import Vue from "vue";
import ItemSelector from "../Common/ItemSelector.vue";
import PlusoneGiftCard from "./PlusoneGiftCard.vue";
import { Item, SelectedSkus } from "@/components/CartCommon/cart";
import PlusoneGiftIcon from "../Common/Icons/PlusoneGiftIcon.vue";

export default Vue.extend({
  props: {
    itemIndex: Number as () => number,
  },
  components: {
    ItemSelector,
    PlusoneGiftCard,
    PlusoneGiftIcon,
  },
  computed: {
    item(): Item | null {
      return this.$store.state.items[this.itemIndex];
    },
    selectedSkus(): SelectedSkus | null {
      return this.item!!.selectedOptions.skus;
    },
    optionItems(): SowExp.OptionItem[] {
      return this.item!!.optionItems;
    },
    hasMultipleCards(): boolean {
      return this.optionItems.length >= 3;
    }
  },
  methods: {
    currentOptionItem(optionItemId: number) {
      if (this.selectedSkus) {
        return this.selectedSkus[optionItemId];
      } else {
        return false;
      };
    },
  },
});
