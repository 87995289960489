















import Vue from "vue";

export default Vue.extend({
  props: {
    breadcrumbs: {
      type: Array
    }
  }
});
