












































import Vue from "vue";
import ItemDetail from "../Common/ItemDetail.vue";
import NoshiForm from "./NoshiForm.vue";

import { Item, SelectedNoshi, SelectedWrapping } from "@/components/CartCommon/cart";

export default Vue.extend({
  props: {
    itemIndex: Number as () => number,
    card: Object,
    noshiObjectives: {
      type: Array as () => SowExp.NoshiObjective[]
    },
    selectedNoshi: {
      type: Object as () => SelectedNoshi | null,
      required: false
    },
    selectedWrapping: {
      type: Object as () => SelectedWrapping | null,
      required: false
    },
  },
  components: {
    ItemDetail,
    NoshiForm,
  },
  data() {
    return {
      isModalOpen: false,
      selectedNoshiLocal: this.selectedNoshi,
    };
  },
  created() {
    if (this.selectedNoshi == null) {
      this.selectedNoshiLocal = {
        objective: null,
        use: null,
        typeId: 0,
        typeName: null,
        isUseMeigi: false,
        naire: null,

        customOmotegaki: null,
        nairePattern: 0,

        companyName: null,
        fullName1: null,
        fullName2: null,
        fullName3: null,
        lastName: null,
        firstName1: null,
        firstName2: null,
        firstName3: null,
        kana: null
      };
    } else {
      this.selectedNoshiLocal = this.selectedNoshi;
    };

    // NoshiCardに初期値を送る
    this.$emit('noshiDetailCanged', this.selectedNoshiLocal, false);
  },
  computed: {
    isNoFreeWrappingSelected(): boolean {
      return this.selectedWrapping?.price != 0;
    },
  },
  methods: {
    onNoshiDetailCanged(noshi: SelectedNoshi) {
      this.$emit('noshiDetailCanged', noshi, true);
    }
  },
});
