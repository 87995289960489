







import Vue from "vue";
import { showDialog } from "@/util/show_dialog";

export default Vue.extend({
  props: {
    optionName: String,
  },
  methods: {
    async onDeleteOption() {
      showDialog(`${this.optionName}を削除しますか？`, {
        yesLabel: "削除する",
        yesHandler: () => {
          this.$emit('deleteOption');
        },
        noLabel: "キャンセル"
      });
    },
  },
});
