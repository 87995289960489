



























import Vue from "vue";
import ItemDetail from "../Common/ItemDetail.vue";
import OptionSkuList from "./OptionSkuList.vue";
import { SelectedSku } from "@/components/CartCommon/cart";

export default Vue.extend({
  props: {
    optionItem: {
      type: Object as () => SowExp.OptionItem
    },
    currentOptionItem: {
      type: Object as () => SelectedSku | null
    },
  },
  components: {
    ItemDetail,
    OptionSkuList,
  },
  data() {
    return {
      isModalOpen: false,
    };
  },
  computed: {
    currentOptionSkuId() {
      return this.currentOptionItem?.skuId;
    },
    subImages() {
      return this.optionItem.subImages
        .sort((a, b) => a.sortNumber - b.sortNumber)
        .map(s => s.imageUrl);
    },
    subImageDescriptions() {
      return this.optionItem.subImages.map(s => s.description);
    },
  },
  methods: {
    skuKey(sku: any | null): string {
      return sku == null
        ? `optionItem-${this.optionItem.id}-none`
        : `optionItem-${this.optionItem.id}-sku-${sku.id}`;
    },

    onSkuSelected(skuId: number) {
      this.$emit("skuSelected", skuId);
    },
  },
});
