import Vue from "vue";
import Vuex, { Commit } from "vuex";
import {
  CartData,
  SelectedOptions,
  CommonMasterData,
  Item,
  Coupon,
  SelectedSkus,
  SelectedSku,
  SelectedWrapping,
  SelectedMessageCard,
  SelectedNoshi,
  SelectedGiftBag,
  UnitOption,
} from "@/components/CartCommon/cart";
import {
  fetchCart,
  updateCatalogQuantity,
  updateWrapping,
  updateNoshi,
  updateMessageCard,
  updateGiftBag,
  updatePlusoneGift,
  deleteItem,
  updateSkuQuantity,
  updateSelectedCoupon,
} from "@/api/cart";

declare const window: Window['window'] & {
  dataLayer: object[];
};

Vue.use(Vuex);

// mutation method names
const SET_FLASH = "SET_FLASH";
const SET_COMMON_MASTER_DATA = "SET_COMMON_MASTER_DATA";
const SET_CART_TOTAL_PRICE = "SET_CART_TOTAL_PRICE";
const SET_CART_SHIPPING_FEE = "SET_CART_SHIPPING_FEE";
const SET_ITMES = "SET_ITMES";
const SET_COUPONS = "SET_COUPONS";
const SET_HAS_PAPER_GIFT = "SET_HAS_PAPER_GIFT";
const SET_GA4DATA_HASH = "SET_GA4DATA_HASH"
const SET_GA4DATA_AMAPAY_HASH = "SET_GA4DATA_AMAPAY_HASH"

export default new Vuex.Store({
  state: {
    flash: null as string | null,
    items: [] as Item[],
    coupons: [] as Coupon[],
    commonMasterData: null as CommonMasterData | null,
    cartTotalPrice: 0,
    cartShippingFee: 0,
    isError: false,
    hasPaperGift: false,
    ga4DataHash: {},
    ga4DataAmapayHash: {},
    isLoading: false,
  },

  actions: {
    // vuex内のflashを削除する
    removeFlash({ commit }) {
      commit(SET_FLASH, null);
    },
    // カート全体をリフレッシュする
    async refreshCart({ commit }) {
      try {
        const res = await fetchCart();
        const cartData = res.data as CartData;

        commit(SET_FLASH, cartData.flash);
        commit(SET_COMMON_MASTER_DATA, cartData.commonMasterData);
        commit(SET_CART_TOTAL_PRICE, cartData.totalPrice);
        commit(SET_CART_SHIPPING_FEE, cartData.shippingFee);
        commit(SET_HAS_PAPER_GIFT, cartData.hasPaperGift);
        commit(SET_COUPONS, cartData.coupons);

        const items = cartData.items.map(item => {
          try {
            const itemClone = { ...item };

            // ラッピングのデフォルト状態をセットする
            // ちなみに、wrappingのidは可変（例: 以下はidが15, 18だが、複数のitemがあると、このidは可変）
            // id: 15 => "通常のギフト包装"
            // id: 18 => "有料ラッピング"
            const selectedWrappingId = item.selectedOptions.wrapping
              ? item.selectedOptions.wrapping.id
              : null;

            let selectedWrapping = item.wrappings.find(w => w.id == selectedWrappingId)!!;

            // 何も選択されていないとき、Wrappingが期限切れでなくなったとき、通常のギフト包装（無料）
            if (!selectedWrappingId) {
              selectedWrapping = item.wrappings.find(w => w.price == 0)!!;
            } else if (!selectedWrapping) {
              selectedWrapping = item.wrappings.find(w => w.price == 0)!!;
              // wrapping更新
              updateWrapping(item.index, selectedWrapping.id);
              updateNoshi(item.index, item.selectedOptions.noshi);
            };

            itemClone.selectedOptions.wrapping = selectedWrapping;

            return itemClone;
          } catch (e) {
            return null;
          }
        }).filter(Boolean);

        commit(SET_ITMES, items);
        // カートリフレッシュ毎にGA4用データを生成してga4DataHashに格納
        let optionArray = [] as UnitOption[];
        const cartTotalPrice = cartData.totalPrice;
        const catalogArray = items.map(item => ({ item_name: item?.catalogName, price: item?.price, quantity: item?.quantity }));
        for( var i=0; i<items.length; i++ ) {
          optionArray.push(JSON.parse(JSON.stringify(items[i]?.unitOptions)));
          optionArray.push(JSON.parse(JSON.stringify(items[i]?.itemOptions)));
        };
        // hashには常に最新のデータ1件だけを持ちたいので初期化する
        let ga4DataHash = {};
        let ga4DataAmapayHash = {};
        commit(SET_GA4DATA_HASH, ga4DataHash);
        commit(SET_GA4DATA_AMAPAY_HASH, ga4DataAmapayHash);

        ga4DataHash = {
          event: "begin_checkout",
          ecommerce: {
            value: cartTotalPrice,//収益【必須】支払い合計(税込)
            currency: "JPY", //通貨【必須】
            items: catalogArray.concat(optionArray.flat()),
          }
        };
        ga4DataAmapayHash = {
          event: "add_payment_info",
          ecommerce: {
            value: cartTotalPrice,//収益【必須】支払い合計(税込)
            currency: "JPY", //通貨【必須】
            payment_type: "AmazonPay",
            items: catalogArray.concat(optionArray.flat()),
          }
        };
        commit(SET_GA4DATA_HASH, ga4DataHash);
        commit(SET_GA4DATA_AMAPAY_HASH, ga4DataAmapayHash);
      } catch (e) {
        // todo どこでセットするか。。。
        console.error(e);
        throw e;
      }
    },

    // default action pattern for updating cart items and options
    async performCartAction({ dispatch }, action) {
      this.state.isLoading = true;
      try {
        // yield to action
        await new Promise(resolve => {
          setTimeout(async () => {
            await action();
            resolve();
          }, 1000);
        });
        await dispatch("refreshCart");
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.state.isLoading = false;
      }
    },
    async loadCart({ dispatch }) {
      await dispatch("performCartAction", async () => {
        return true; // empty action to trigger cart refresh in 'performCartAction'
      });
    },
    // アイテムを削除する
    async deleteItem({ dispatch }, itemId: number) {
      await dispatch("performCartAction", async () => {
        await deleteItem(itemId);
      });
    },
    // カタログの数量を変更する
    async updateCatalogQuantity(
      { dispatch },
      {
        itemId,
        catalogId,
        quantity,
      }: { itemId: number; catalogId: number; quantity: number },
    ) {
      await dispatch("performCartAction", async () => {
        await updateCatalogQuantity(itemId, catalogId, quantity);
      });
    },
    // ラッピングを変更する
    async updateWrapping(
      { dispatch },
      { itemId, wrapping }: { itemId: number; wrapping: SelectedWrapping },
    ) {
      await dispatch("performCartAction", async () => {
        await updateWrapping(itemId, wrapping.id);
      });
    },
    // のしを変更する
    async updateNoshi(
      { dispatch },
      { itemId, noshi }: { itemId: number; noshi: SelectedNoshi },
    ) {
      await dispatch("performCartAction", async () => {
        await updateNoshi(itemId, noshi);
      });
    },
    // メッセージカードを変更する
    async updateMessageCard(
      { dispatch },
      {
        itemId,
        messageCard,
      }: { itemId: number; messageCard: SelectedMessageCard | null },
    ) {
      await dispatch("performCartAction", async () => {
        await updateMessageCard(itemId, messageCard);
      });
    },
    // GiftShopperを変更する
    async updateGiftBag(
      { dispatch },
      {
        itemId,
        giftBag,
      }: {
        itemId: number;
        giftBag: SelectedGiftBag | null;
      },
    ) {
      await dispatch("performCartAction", async () => {
        await updateGiftBag(itemId, giftBag);
      });
    },
    // PlusoneGiftを変更する
    async updatePlusoneGift(
      { dispatch },
      {
        itemId,
        skus,
      }: {
        itemId: number;
        skus: SelectedSkus;
      },
    ) {
      await dispatch("performCartAction", async () => {
        await updatePlusoneGift(itemId, skus);
      });
    },
    // skuの個数を変更する
    async updateSkuQuantity(
      { dispatch },
      {
        itemIndex,
        selectedSku,
      }: {
        itemIndex: number;
        selectedSku: SelectedSku;
      },
    ) {
      await dispatch("performCartAction", async () => {
        await updateSkuQuantity(itemIndex, selectedSku);
      });
    },

    // couponの選択を変更
    async updateSelectedCoupon(
      { dispatch },
      {
        coupon,
      }: {
        coupon: Coupon;
      },
    ) {
      await dispatch("performCartAction", async () => {
        await updateSelectedCoupon(coupon);
      });
    },
  },

  mutations: {
    [SET_FLASH](state, flash: string) {
      state.flash = flash;
    },
    [SET_COMMON_MASTER_DATA](state, masterData: CommonMasterData) {
      state.commonMasterData = masterData;
    },
    [SET_CART_TOTAL_PRICE](state, price: number) {
      state.cartTotalPrice = price;
    },
    [SET_CART_SHIPPING_FEE](state, fee: number) {
      state.cartShippingFee = fee;
    },
    [SET_ITMES](state, items: Item[]) {
      state.items = items;
    },
    [SET_COUPONS](state, coupons: Coupon[]) {
      state.coupons = coupons;
    },
    [SET_HAS_PAPER_GIFT](state, hasPaperGift: boolean) {
      state.hasPaperGift = hasPaperGift;
    },
    [SET_GA4DATA_HASH](state, ga4DataHash: {}) {
      state.ga4DataHash = ga4DataHash;
    },
    [SET_GA4DATA_AMAPAY_HASH](state, ga4DataAmapayHash: {}) {
      state.ga4DataAmapayHash = ga4DataAmapayHash;
    },
  },
});
