



























import Vue from "vue";
import Flickity from "vue-flickity";

export default Vue.extend({
  props: {
    name: String,
    price: Number,
    description: String,
    images: Array,
    imageDescriptions: {
      type: Array,
      required: false,
      default: function() {
        return [];
      },
    },
  },
  components: {
    Flickity,
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 0,
        contain: true,
        prevNextButtons: false,
        pageDots: false
      },
      slideCounter: `1 / ${this.images.length}`,
      mainImage: this.images[0],
      imageDescription: this.imageDescriptions[0],
    };
  },
  methods: {
    showImage(val: number) {
      this.mainImage = this.images[val];
      this.imageDescription = this.imageDescriptions[val];
      this.setCounter(val);
    },
    setCounter(val: number) {
      this.slideCounter = (val + 1) + '/' + this.images.length;
    }
  },
});
