



















































import Vue from "vue";
import VueThinModal from "vue-thin-modal";
import "vue-thin-modal/dist/vue-thin-modal.css";
Vue.use(VueThinModal);

export default Vue.extend({
  props: {
    // vue-thin-modal用のmodal識別子
    // globalな名前空間でユニークにする必要がある
    modalName: String as () => string,
    isModalOpen: Boolean as () => boolean,
    title: String as () => string,
    confirmLabel: String as () => string
  },
  data() {
    return {
      modalTransition: { name: "modal-transition" },
      isBrowserBack: false,
    };
  },
  watch: {
    isModalOpen() {
      if (this.isModalOpen) {
        this.openModal();
      } else {
        this.closeModal();
      }
    }
  },
  methods: {
    openModal() {
      // ブラウザのバックボタンや、iphoneの右スワイプ対応のため、
      // モーダルを開いたときにhistoryをpushし、onpopstateのイベントハンドラを登録しておく
      window.history.pushState(null, "", null);
      window.onpopstate = this.windowOnPopStateHandler;

      (this as any).$modal.push(this.modalName);
    },
    closeModal() {
      // historyとイベントハンドラを戻す
      window.onpopstate = null;
      // ブラウザバックでないとき
      if (!this.isBrowserBack) {
        history.back();
        this.isBrowserBack = false;
      };

      (this as any).$modal.pop();
    },
    // ブラウザのhistorybackのハンドラ
    windowOnPopStateHandler(event: any) {
      // ブラウザネイティブの戻るトランジションがあるので、
      // このモーダルのトランジションは一時的にoffにする
      // そうしないとiphoneで戻るスワイプをしたときに戻った後の画面でトランジションのチラツキがおきてしまう
      const tmpTransition = this.modalTransition;
      this.modalTransition = { name: "no-transition" };
      this.isBrowserBack = true;

      this.$nextTick(() => {
        // イベントハンドラをリセット
        window.onpopstate = null;
        // キャンセルイベントを発火
        this.$emit("cancel");

        // トランジションを元に戻す
        this.$nextTick(() => {
          this.modalTransition = tmpTransition;
        });
      });
    },
    onBackButtonClicked() {
      // キャンセルイベントを発火
      this.$emit("cancel");
    },
    onConfirmButtonConfirmed() {
      // 確認イベントを発火
      this.$emit("confirm");
    },
    onModalBeforeOpen() {},
    onModalClosed() {}
  }
});
