




















import Vue from "vue";
export default Vue.extend({
  props: {
    stepNumber: Number
  },
  data() {
    return {};
  }
});
