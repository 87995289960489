





















import Vue from "vue";

export default Vue.extend({
  props: {
    skus: {
      type: Array as () => SowExp.Sku[],
    },
    currentOptionSkuId: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      selectedSkuId: this.currentOptionSkuId,
    };
  },
  created() {
    if (!this.currentOptionSkuId) {
      this.onSkuSelected(this.skus[0]);
    };
  },
  methods: {
    title(sku: SowExp.Sku) {
      return sku.isOutOfStock ? `${sku.name} （在庫切れ）` : sku.name;
    },
    selected(selectedSku: SowExp.Sku): boolean {
      return this.selectedSkuId == selectedSku.id;
    },
    disabled(sku: SowExp.Sku): boolean {
      return sku.isOutOfStock;
    },
    onSkuSelected(selectedSku: SowExp.Sku) {
      if (selectedSku.isOutOfStock) {
        return;
      };
      this.selectedSkuId = selectedSku.id;
      this.$emit("skuSelected", selectedSku.id);
    },
  }
});
