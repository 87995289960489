
import Vue from "vue";

export default Vue.extend({
  methods: {
    detectEmoji(k) {
      const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

      if (typeof(k) == 'string') {
        if (k.match(regex)) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    emojiErr(noshi, errors) {
      for(let k in noshi) {
        if (this.detectEmoji(noshi[k])) {
          return errors.push(`使用できない文字が含まれています`);
        }
      }
    },
    validateNoshiPaper(noshi, errors) {
      if (noshi != null) {
        if (noshi.typeId === null) {
          errors.push("在庫切れのため他ののしをお選びください");
        }
        this.emojiErr(noshi, errors);
        if (noshi.customOmotegaki != null) {
          if (noshi.customOmotegaki.length > 8) {
            errors.push("表書きは最大8文字です");
          }
        }
        if (noshi.objective == null) {
          errors.push("「のしあり」の場合、目的は必須です");
        }
        if (noshi.isUseMeigi) {
          if (noshi.naire == null || noshi.naire === "") {
            errors.push("「名義あり」の場合、名義を入力してください");
          }
        }
        if (noshi.nairePattern == 1) {
          if (noshi.fullName1 == null || noshi.fullName1 == "") {
            errors.push("氏名を入力してください");
          } else if (noshi.fullName1.length > 6) {
            errors.push("氏名は最大6文字です");
          }
          if (noshi.fullName2 != null) {
            if (noshi.fullName2.length > 6) {
              errors.push("氏名は最大6文字です");
            }
          }
          if (noshi.fullName3 != null) {
            if (noshi.fullName3.length > 6) {
              errors.push("氏名は最大6文字です");
            }
          }
        }
        if (noshi.nairePattern == 2) {
          if (noshi.companyName == null || noshi.companyName == "") {
            errors.push("所属名を入力してください");
          } else if (noshi.companyName.length > 30) {
            errors.push("所属名は最大30文字です");
          }

          if (noshi.fullName1 == null || noshi.fullName1 == "") {
            errors.push("氏名を入力してください");
          } else if (noshi.fullName1.length > 6) {
            errors.push("氏名は最大6文字です");
          }
        }
        if (noshi.nairePattern == 3) {
          if (noshi.lastName == null || noshi.lastName == "") {
            errors.push("姓を入力してください");
          } else if (noshi.lastName.length > 3) {
            errors.push("姓は最大3文字です");
          }
          if (noshi.firstName1 == null || noshi.firstName1 == "") {
            errors.push("名を入力してください");
          } else if (noshi.firstName1.length > 4) {
            errors.push("名は最大4文字です");
          }

          if (noshi.firstName2 != null) {
            if (noshi.firstName2.length > 4) {
              errors.push("名は最大4文字です");
            }
          }
          if (noshi.firstName3 != null) {
            if (noshi.firstName3.length > 4) {
              errors.push("名は最大4文字です");
            }
          }
        }
        if (noshi.nairePattern == 4) {
          if (noshi.firstName1 == null || noshi.firstName1 == "") {
            errors.push("名を入力してください");
          }
          if (noshi.firstName1 && noshi.firstName1.length > 4) {
            errors.push("名は最大4文字です");
          }
          if (noshi.kana && noshi.kana.length > 7) {
            errors.push("かなは最大7文字です");
          }
        }
      }
    }
  }
});
