



























import Vue from "vue";
import CardDetail from "../Common/CardDetail.vue";
import CommonModalScreen from '../Common/CommonModalScreen.vue';
import DeleteButton from '../Common/DeleteButton.vue';
import ItemPage from '../Common/ItemPage.vue';
import { SelectedGiftBag } from "@/components/CartCommon/cart";
import { alertError } from "@/util/alert_errors";

export default Vue.extend({
  props: {
    itemIndex: Number as () => number,
    giftBag: Object as () => SelectedGiftBag,
    currentGiftBag: Boolean,
  },
  data() {
    return {
      isModalOpen: false,
      modalTransition: { name: "modal-transition" },
      // descriptionText: "メッセージを書いてください。\n長すぎないようにお願いします"
      descriptionText: "プレゼントを入れるのにぴったりなギフトショッパーです。\n誕生日プレゼントや結婚祝い、お礼、内祝いなどシーンを選ばずにご利用いただけるシンプルなデザイン。折りたたんだ状態で、品物と一緒に梱包してお届けします。 \nサイズ：200×90×200mm（持ち手含まず） \n主な素材：紙"
    };
  },
  components: {
    CardDetail,
    CommonModalScreen,
    DeleteButton,
    ItemPage,
  },
  computed: {
    modalName(): string {
      return `cart-modal-giftbag-${this.itemIndex}-${this.giftBag.id}`;
    },
    images(): Array<string> {
      return [
        require("@/assets/images/cart2019/giftshopper_sample1.jpg"),
        require("@/assets/images/cart2019/giftshopper_sample2.jpg"),
        require("@/assets/images/cart2019/giftshopper_sample3.jpg"),
      ];
    },
    description(): string {
      return this.descriptionText.replace(/\n/g, '<br>');
    },
  },
  methods: {
    onClick() {
      // スクロール中にカードのクリック操作が起きないように
      // ItemSelectorでdraggingを制御
      if (window.dragging) {
        return;
      };

      this.isModalOpen = true;
    },
    onCancel() {
      this.isModalOpen = false;
    },
    async onConfirm() {
      this.updateGiftBag(this.giftBag);
    },
    onDeleteOption() {
      this.updateGiftBag(null);
    },
    async updateGiftBag(giftBag) {
      try {
        await this.$store
          .dispatch("updateGiftBag", {
            itemId: this.itemIndex,
            giftBag: giftBag,
          })
          .catch(() => {
            throw new Error("エラーが発生しました");
          });
        this.isModalOpen = false;
      } catch (e) {
        alertError(e);
      }
    },
  },
});
