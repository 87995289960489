

















import Vue from "vue";
import ItemSelector from "../Common/ItemSelector.vue";
import NoshiCard from '../Noshi/NoshiCard.vue';
import { Item, SelectedNoshi } from "@/components/CartCommon/cart";
import NoshiIcon from "../Common/Icons/NoshiIcon.vue";

export default Vue.extend({
  props: {
    itemIndex: Number as () => number,
  },
  components: {
    ItemSelector,
    NoshiCard,
    NoshiIcon,
  },
  computed: {
    item(): Item | null {
      return this.$store.state.items[this.itemIndex];
    },
    selectedNoshi(): SelectedNoshi | null {
      return this.item!!.selectedOptions.noshi;
    },
  },
  methods: {
    noshis() {
      return [{
        "name": "のし",
        "price": 0,
        "thumbnailUrl": require('@/assets/images/cart2019/noshi01.png'),
        "images": [
          require('@/assets/images/option/noshi01.jpg'),
          require('@/assets/images/option/noshi02.jpg'),
          require('@/assets/images/option/noshi03.jpg'),
          require('@/assets/images/option/noshi04.jpg'),
          require('@/assets/images/option/noshi05.jpg'),
          require('@/assets/images/option/noshi06.jpg'),
          require('@/assets/images/option/noshi07.jpg'),
        ],
      }]
    },
    currentNoshi() {
      return this.selectedNoshi != null;
    },
  },
});
