<template>
    <svg data-name="のし" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
      <title>Noshi Icon</title>
      <defs>
        <clipPath id="a">
          <path data-name="長方形 2111" style="fill:none" d="M0 0h20v20H0z"/>
        </clipPath>
      </defs>
      <g data-name="グループ 42">
        <path data-name="パス 14" d="M15.477 7.147a3.491 3.491 0 0 0-2.5 2.3 4.333 4.333 0 0 0 3.511-.687c.152-.174.3-.444.07-1a.912.912 0 0 0-1.083-.61" transform="translate(-1.693 -.931)" style="fill:#337ab0"/>
        <path data-name="パス 15" d="M6.968 7.147a1.511 1.511 0 0 0-.2-.014.885.885 0 0 0-.882.623c-.228.555-.083.825.07 1a4.334 4.334 0 0 0 3.511.687 3.491 3.491 0 0 0-2.5-2.3" transform="translate(-.752 -.93)" style="fill:#337ab0"/>
        <path data-name="パス 16" d="M18.261 0H1.739A1.739 1.739 0 0 0 0 1.739v16.522A1.739 1.739 0 0 0 1.739 20h16.522A1.739 1.739 0 0 0 20 18.261V1.739A1.739 1.739 0 0 0 18.261 0M16.1 8.972a5.067 5.067 0 0 1-3.759 1.338c1.395 1.4 3.205 3.226 3.719 3.741l-1.23 1.23c-.652-.652-3.388-3.407-4.718-4.747-1.327 1.34-4.062 4.095-4.712 4.747l-1.23-1.23c.516-.516 2.33-2.343 3.727-3.748-.053 0-.106.01-.159.01A5.124 5.124 0 0 1 3.9 8.972a2.579 2.579 0 0 1-.371-2.807 2.626 2.626 0 0 1 2.913-1.672A5.083 5.083 0 0 1 10 7.162a5.083 5.083 0 0 1 3.558-2.669 2.632 2.632 0 0 1 2.917 1.672 2.579 2.579 0 0 1-.375 2.807" style="fill:#337ab0"/>
      </g>
    </svg>
</template>

<script>
  export default {
    name: 'NoshiIcon',
  };
</script>