




























import Vue from "vue";
import MessageCardPlainPreview from "./MessageCardPlainPreview.vue";
import { showDialog } from "@/util/show_dialog";

export default Vue.extend({
  props: {
    initialMessage: {
      type: String as () => string | null
    }
  },
  components: {
    MessageCardPlainPreview
  },
  data() {
    return {
      message: this.initialMessage || "",
      maxMessageLen: 200
    };
  },
  methods: {
    async onInput(e: Event) {
      const oldMessage = this.message;
      const newMessage = (e.target as any).value;

      this.message = newMessage;

      const error = await this.validate(newMessage).catch(
        () => "エラーが発生しました"
      );

      if (error != null) {
        this.message = oldMessage;
        showDialog(error);
        return;
      }

      this.$emit("changed", this.message);
    },
    async validate(message: string): Promise<string | null> {
      if (message.length > this.maxMessageLen) {
        return `${this.maxMessageLen}文字以内でご入力ください`;
      }
      const exceeded = await (this.$refs.MessageCardPlainPreviewRef as any)
        .checkHeightExceeded()
        .catch(() => true);
      if (exceeded) {
        return "10行以内でご入力ください";
      }
      return null;
    }
  }
});
