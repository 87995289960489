<template>
    <svg data-name="プラスワンギフト（複数選択可）" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
      <title>Plus One Gift Icon</title>
      <defs>
        <clipPath id="a">
          <path data-name="長方形 2114" style="fill:none" d="M0 0h20v20H0z"/>
        </clipPath>
      </defs>
      <g data-name="グループ 48">
        <path data-name="パス 20" d="M18.261 0H1.739A1.739 1.739 0 0 0 0 1.739v16.522A1.739 1.739 0 0 0 1.739 20h16.522A1.739 1.739 0 0 0 20 18.261V1.739A1.739 1.739 0 0 0 18.261 0m-3.277 10.87H10.87v3.93H9.13v-3.93H5.124V9.13H9.13V4.936h1.74V9.13h4.115z" style="fill:#337ab0"/>
      </g>
    </svg>
</template>

<script>
  export default {
    name: 'PlusoneGiftIcon',
  };
</script>