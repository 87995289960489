






























import Vue from "vue";
import CardDetail from "../Common/CardDetail.vue";
import CommonModalScreen from '../Common/CommonModalScreen.vue';
import DeleteButton from '../Common/DeleteButton.vue';
import NoshiPage from './NoshiPage.vue';
import NoshiValidate from "@/mixins/noshiValidate.vue"
import { Item, SelectedWrapping, SelectedNoshi } from "@/components/CartCommon/cart";
import { alertError } from "@/util/alert_errors";
import { showDialog } from "@/util/show_dialog";

export default Vue.extend({
  props: {
    itemIndex: Number as () => number,
    noshi: Object as () => SelectedNoshi,
    currentNoshi: Boolean,
  },
  data() {
    return {
      isModalOpen: false,
      dirty: false,
      selectedNoshiLocal: null as SelectedNoshi | null,
      hasError: false,
    };
  },
  components: {
    CardDetail,
    CommonModalScreen,
    DeleteButton,
    NoshiPage,
  },
  mixins: [NoshiValidate],
  computed: {
    modalName(): string {
      return `cart-modal-noshi-${this.itemIndex}`;
    },
    item(): Item | null {
      return this.$store.state.items[this.itemIndex];
    },
    noshiObjectives(): SowExp.NoshiObjective[] {
      return this.$store.state.commonMasterData.noshiObjectives;
    },
    selectedWrapping(): SelectedWrapping | null {
      return this.item!!.selectedOptions.wrapping;
    },
    selectedNoshi(): SelectedNoshi | null {
      return this.item!!.selectedOptions.noshi;
    },
  },
  methods: {
    onClick() {
      // スクロール中にカードのクリック操作が起きないように
      // ItemSelectorでdraggingを制御
      if (window.dragging) {
        return;
      };

      this.isModalOpen = true;
      this.dirty = false;
    },
    onCancel() {
      // 一度でものしの選択肢を変更したら(dirty=true)
      //「キャンセル」を押下した際にユーザーに対して注意を促す
      if (this.dirty) {
        showDialog("編集内容を破棄しますか？", {
          yesLabel: "破棄する",
          yesHandler: () => {
            this.selectedNoshiLocal = this.selectedNoshi;

            this.dirty = false;
            this.isModalOpen = false;
          },
          noLabel: "続ける"
        });
      } else {
        this.isModalOpen = false;
      }
    },
    onConfirm() {
      if (!this.validate()) {
        return false;
      }

      this.updateNoshi(this.selectedNoshiLocal);
    },
    onDeleteOption() {
      this.updateNoshi(null);
    },
    async updateNoshi(noshi) {
      try {
        await this.$store
          .dispatch("updateNoshi", {
            itemId: this.itemIndex,
            noshi: noshi,
          })
          .catch(() => {
            throw new Error("エラーが発生しました");
          });
        this.isModalOpen = false;
      } catch (e) {
        alertError(e);
      }
    },
    validate() {
      const noshi = this.selectedNoshiLocal;
      const errors: string[] = [];

      (this as any).validateNoshiPaper(noshi, errors);

      if (errors.length >= 1) {
        alert(`エラーがあります\n\n${errors.join("\n")}`);
        return false;
      } else {
        return true;
      }
    },
    onNoshiDetailCanged(noshi: SelectedNoshi, isDirty: boolean) {
      if (isDirty) {
        this.dirty = true;
      }
      this.selectedNoshiLocal = noshi;
    },
  },
});
