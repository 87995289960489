

















































































import Vue from "vue";
import _ from "lodash";
import { SelectedSku, SelectedSkus } from "@/components/CartCommon/cart";
import { alertError } from "@/util/alert_errors";
import { Item } from "@/components/CartCommon/cart";

export default Vue.extend({
  props: {
    item: Object as () => Item,
    wrapping: Object as () => SowExp.Wrapping | null,
    messageCard: Object as () => SowExp.MessageCard | null,
    noshi: Object as () => SowExp.Noshi | null,
    giftBag: Object as () => SowExp.GiftBag | null,
    skus: Array as () => { sku: SowExp.Sku; optionItem: SowExp.OptionItem }[],
  },
  methods: {
    quantityOptions(sku: {
      sku: SowExp.Sku;
      optionItem: SowExp.OptionItem;
    }): number[] {
      return _.range(1, sku.sku.maxSelectSize + 1);
    },
    onSkuQuantityChanged(
      sku: { sku: SowExp.Sku; optionItem: SowExp.OptionItem },
      quantityStr: string
    ) {
      const selectedSku: SelectedSku = {
        optionItemId: sku.optionItem.id,
        skuId: sku.sku.id,
        quantity: Number(quantityStr),
      };
      this.$emit("skuQuantityChanged", selectedSku);
    },
    deleteNoshi(): void {
      if (!this.confirmDelete()) {
        return;
      }
      const method = "updateNoshi";
      const payload = { noshi: null };
      this.dispatchToStore(method, payload);
    },
    deleteMessageCard(): void {
      if (!this.confirmDelete()) {
        return;
      }
      const method = "updateMessageCard";
      const payload = { messageCard: null };
      this.dispatchToStore(method, payload);
    },
    deleteGiftBag(): void {
      if (!this.confirmDelete()) {
        return;
      }
      const method = "updateGiftBag";
      const payload = { giftBag: null };
      this.dispatchToStore(method, payload);
    },
    deleteSku(sku): void {
      if (!this.confirmDelete()) {
        return;
      }
      const skus = this.selectedSkus;
      const skuKey = Object.keys(skus).find(
        key => skus[key].skuId === sku.sku.id,
      );
      if (skuKey !== "") {
        delete skus[Number(skuKey)];
      } else {
        return;
      }
      const method = "updatePlusoneGift";
      const payload = { skus: skus };
      this.dispatchToStore(method, payload);
    },
    confirmDelete(): boolean {
      return window.confirm("削除してもよろしいですか？");
    },
    async dispatchToStore(method, payload): Promise<void> {
      payload.itemId = this.itemIndex;
      try {
        await this.$store.dispatch(method, payload);
      } catch (e) {
        alertError(e);
      }
    },
  },
  computed: {
    itemQuantity(): number {
      return this.item.quantity;
    },
    itemIndex(): number {
      return this.item.index;
    },
    selectedSkus(): SelectedSkus {
      if (this.item!!.selectedOptions.skus) {
        return this.item!!.selectedOptions.skus;
      } else {
        return {};
      }
    },
  },
});
