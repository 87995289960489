




























































































import Vue from "vue";
import _ from "lodash";
import MessageCardCelebrationPreview from "./MessageCardCelebrationPreview.vue";
import { showDialog } from "@/util/show_dialog";

const maxHeight  = 999;
const maxWeight = 9999;

export interface MessageCardCelebrationForm {
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  birthYear: number | null;
  birthMonth: number | null;
  birthDate: number | null;
  weight: number | null;
  height: number | null;
  message: string;
}

export default Vue.extend({
  props: {
    initialCelebrationFormData: Object as () => MessageCardCelebrationForm
  },
  components: {
    MessageCardCelebrationPreview
  },
  data() {
    return {
      form: { ...this.initialCelebrationFormData },
      birthYearOptions: _.range(2000, 2100),
      birthMonthOptions: _.range(1, 13),
      birthDateOptions: _.range(1, 32),
      maxMessageLen: 60
    };
  },
  watch: {
    form: {
      handler() {
        this.validateWeight();
        this.validateHeight();
        this.$emit("changed", this.form);
      },
      deep: true
    },
    async "form.message"(newMessage, oldMessage) {
      const error = await this.validateMessage(newMessage).catch(
        () => "エラーが発生しました"
      );
      if (error != null) {
        // エラーがあったら変更前のメッセージに戻す
        this.form.message = oldMessage;
        showDialog(error);
        return;
      }
    }
  },
  methods: {
    async validateMessage(message: string): Promise<string | null> {
      if (message.length > this.maxMessageLen) {
        return `${this.maxMessageLen}文字以内でご入力ください`;
      }
      const exceeded = await (this.$refs
        .MessageCardCelebrationPreviewRef as any)
        .checkHeightExceeded()
        .catch(() => true);
      if (exceeded) {
        return "4行以内でご入力ください";
      }
      return null;
    },
    validateWeight() {
      if (Number(this.form.weight) > maxWeight) {
        this.form.weight = this.divide10(this.form.weight);
      }
    },
    validateHeight() {
      if (Number(this.form.height) > maxHeight) {
        this.form.height = this.divide10(this.form.height);
      }
    },
    divide10(value: number | null) {
      return Math.floor(Number(value) / 10);
    }
  },
  computed: {
    messageLen(): number {
      return this.form.message.length;
    }
  }
});
