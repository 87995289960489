





























import Vue from "vue";
import _ from "lodash";
import { Item } from "@/components/CartCommon/cart";

export default Vue.extend({
  props: {
    item: Object as () => Item
  },
  data() {
    return {
      localQuantity: this.item.quantity,
      quantityOptions: this.item.unitsInStock,
    };
  },
  watch: {
    "item.quantity"() {
      this.localQuantity = this.item.quantity;
    },
    localQuantity() {
      this.$emit("quantityChanged", this.localQuantity);
    }
  },
  methods: {
    onDeleteButtonClicked() {
      if(window.confirm('カートから削除しますか？')){
        this.$emit("delete");
      }
    }
  }
});
