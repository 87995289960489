// ほぼ全てのコンポーネントで必要となるGlobalなメソッドやプラグインをregisterします
// このファイルをimportするとグローバルに登録されます

import Vue from "vue";
import { registerGlobalErrorHandler } from "@/util/global_error_handler";

// todo: vue-cliで最初からついてきたけど、これいるかな？
Vue.config.productionTip = false;

// グローバルなエラーハンドラ
registerGlobalErrorHandler();

// 金額を,区切りの文字列にする
Vue.filter(
  "formatPrice",
  (amount: number | null): string => {
    if (amount == null) return "";
    const arr = amount.toString().split(".");
    arr[0] = arr[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    return arr.join(".");
  }
);
